import type { RequestErrorResponse } from '~/types/api'

export function requestToFormkitErrors(
  errorResponse: RequestErrorResponse | undefined,
  localSources: string[] = [],
  mapper: Record<string, string> = {},
) {
  const localErrors: string[] = []
  const childErrors: Record<string, string[]> = {}

  if (!errorResponse) return { localErrors, childErrors }

  if (errorResponse.detail) {
    localErrors.push(errorResponse.detail)
  }

  for (const key in errorResponse.errors ?? {}) {
    const messages = errorResponse.errors[key]
    const pointer = mapper[key] || key

    // If the source is specified in `localSources`, add to localErrors
    if (localSources.includes(pointer)) {
      localErrors.push(...messages)
      continue
    }

    // If we do have the source, add to childErrors
    childErrors[pointer] = childErrors[pointer] || []
    childErrors[pointer].push(...messages)
  }

  return { localErrors, childErrors }
}
